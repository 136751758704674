<template>
    <v-container class="map-container" fluid fill-height>

        <v-img
            :src="`https://api.mapbox.com/styles/v1/mapbox/${($vuetify.theme.dark ? 'satellite-streets-v10' : 'satellite-streets-v10')}/static/geojson(${encodeURIComponent(JSON.stringify(geoJsonSourceCoverage.data))}),pin-s-communications-tower+04649C(${repeater.Longitude},${repeater.Latitude})/${repeater.Longitude},${repeater.Latitude},8/800x400@2x?access_token=pk.eyJ1IjoibXlnbXJzIiwiYSI6ImNrYjgzODdtcTAwYXcycnMwdjFvZ292bmsifQ.SJP7jVz6UeY-cmtVkYlMTQ`"
            :height="`${height}px`"
            @click="showMap()"
            v-if="showClickBanner"
        />

<!--        <div v-if="showClickBanner">-->
<!--            <v-btn large color="rfaccent2" @click="showMap()" class="click-banner">-->
<!--                Click to Load Map-->
<!--            </v-btn>-->
<!--        </div>-->

        <div id="map" :style="cssVars" v-else></div>

        <v-card
            class="almostblack px-1 pt-2 pb-1 rounded elevation-10 hidden-xs-only"
            id="map-style"
            dark
            v-if="!showClickBanner"
        >
            <v-select
                v-model="mapStyle"
                label="Map Style"
                :items="mapStyles"
                outlined
                dense
                hide-details
            ></v-select>
        </v-card>

        <MapPopupContent :properties="popupProperties" ref="popup-content" v-if="showPopup"/>
    </v-container>
</template>

<style scoped>
    #map {
        width: 100%;
        height: var(--height);
    }

    .map-container {
        padding: 0;
        height: 400px;
    }

    #map-style {
        position: relative;
        top: -85px;
        left: 10px;
    }

    .click-banner {

    }
</style>

<script>
    import mapboxgl from 'mapbox-gl';
    import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
    import MapPopupContent from '@/components/MapPopupContent.vue';
    import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
    import config from '../config';

    let map;

    export default {
        props: ['repeater', 'type', 'geocoder', 'is-draggable', 'height', 'lazy'],

        components: {MapPopupContent},

        data: () => ({
            config: config,
            showClickBanner: false,
            showPopup: false,
            popupProperties: {},
            mapStyles: [
                {value: 'streets-v11', text: 'Streets (Color)'},
                {value: 'light-v10', text: 'Streets (Light)'},
                {value: 'dark-v10', text: 'Streets (Dark)'},
                {value: 'outdoors-v11', text: 'Outdoors'},
                {value: 'satellite-v9', text: 'Satellite'},
                {value: 'satellite-streets-v10', text: 'Satellite Streets'},
            ],
            geoJsonSourceCoverage: {
                type: 'geojson',
                data: {}
            },
        }),

        watch: {
            repeater() {
                this.initMap();
            }
        },

        methods: {
            showMap() {
                this.showClickBanner = false;
                this.$nextTick(this.initMap);
            },

            initMap() {
                mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;
                map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/' + this.mapStyle,
                    center: [this.repeater.Longitude || -100, this.repeater.Latitude || 37], //@todo use fitBounds to capture lower 48 at any screen size
                    zoom: this.repeater.Latitude && this.repeater.Longitude ? 8 : 4
                });

                // create a DOM element for the marker
                let el = document.createElement('div');
                el.className = 'marker';
                el.style.backgroundImage = 'url(/images/favicon32.png)';
                el.style.backgroundSize = 'contain';
                el.style.width = '24px';
                el.style.height = '24px';

                el.addEventListener('mouseenter', () => {
                    map.getCanvas().style.cursor = 'pointer';
                });

                el.addEventListener('mouseleave', () => {
                    map.getCanvas().style.cursor = '';
                });

                this.popupProperties = this.$_.transform(this.repeater, (result, val, key) => {
                    if (key === 'Name') result.title = val;
                    else if (key === 'PL In') result.toneIn = val;
                    else if (key === 'PL Out') result.toneOut = val;
                    else result[key.toLowerCase()] = val;
                });

                this.popupProperties.hideViewDetails = true;

                this.showPopup = true;

                this.$nextTick(() => {
                    const popup = new mapboxgl.Popup()
                        .setDOMContent(this.$refs['popup-content'].$el)
                        .on('open', () => {
                            const point = map.project(popup._lngLat);
                            const popupHeight = popup._container.clientHeight + 50; // Small amount of padding
                            if (point.y - popupHeight < 0) {
                                map.panBy([0, point.y - popupHeight]);
                            }
                        });

                    const marker = new mapboxgl.Marker(el, {
                        draggable: this.isDraggable !== undefined
                    })
                        .setLngLat([this.repeater.Longitude || -100, this.repeater.Latitude || 37])
                        .setPopup(popup)
                        .addTo(map);

                    if (this.repeater.ID) {
                        marker.on('click', () => {
                            marker.togglePopup();
                        });
                    }

                    marker.on('dragend', () => {
                        const lngLat = marker.getLngLat();
                        this.$emit('markerDropped', lngLat);
                    });

                    if (this.repeater.ID) marker.togglePopup();
                });

                if (this.geocoder !== undefined) {
                    map.addControl(
                        new MapboxGeocoder({
                            accessToken: mapboxgl.accessToken,
                            mapboxgl: mapboxgl
                        }), 'top-left'
                    );
                }

                map.addControl(new mapboxgl.FullscreenControl());

                map.addControl(new mapboxgl.NavigationControl());

                map.addControl(
                    new mapboxgl.GeolocateControl({
                        positionOptions: {
                            enableHighAccuracy: true
                        },
                        trackUserLocation: true
                    })
                );

                map.on('style.load', () => {
                    this.addSource();

                    // map.resize();
                });
            },

            addSource() {
                let layers = map.getStyle().layers;
                // find the index of the first symbol layer in the map style
                let firstSymbolId;
                for (let i = 0; i < layers.length; i++) {
                    if (layers[i].type === 'symbol') {
                        firstSymbolId = layers[i].id;
                        break;
                    }
                }

                map.addSource('repeater-coverage', this.geoJsonSourceCoverage);

                map.addLayer({
                    id: "repeater-coverage",
                    source: "repeater-coverage",
                    type: "fill",
                    paint: {
                        "fill-color": this.repeater.Status === 'Online' ? this.$vuetify.theme.themes.dark.success : this.$vuetify.theme.themes.dark.error,
                        "fill-opacity": 0.5
                    },
                }, firstSymbolId);
            },

            switchStyle(layerId) {
                map.setStyle('mapbox://styles/mapbox/' + layerId);
            },

            createGeoJSONCircle(center, radiusInKm, points) {
                if (!points) points = 64;

                let coords = {
                    latitude: center[1],
                    longitude: center[0]
                };

                let km = radiusInKm;

                let ret = [];
                let distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
                let distanceY = km / 110.574;

                let theta, x, y;
                for (let i = 0; i < points; i++) {
                    theta = (i / points) * (2 * Math.PI);
                    x = distanceX * Math.cos(theta);
                    y = distanceY * Math.sin(theta);

                    ret.push([coords.longitude + x, coords.latitude + y]);
                }
                ret.push(ret[0]);

                return ret;
            },

            range(radius, haat) {
                if (!radius) {
                    const distRepeater = ((4.12 * Math.sqrt(haat / 3.28084)));
                    const distRadio = ((4.12 * Math.sqrt(5 / 3.28084)));
                    return Math.round((distRadio + distRepeater) * 10) / 10
                } else {
                    return radius;
                }
            },
        },

        computed: {
            mapStyle: {
                get() {
                    return this.type ? this.type : 'satellite-streets-v10';
                },
                set(newValue) {
                    this.switchStyle(newValue);
                }
            },

            cssVars() {
                return {
                    '--height': (this.height || 300) + 'px'
                }
            },

            isLazy() {
                return !this.$_.isUndefined(this.lazy);
            }
        },

        mounted() {
            this.showClickBanner = this.isLazy;

            let ret = this.createGeoJSONCircle([
                this.repeater.Longitude,
                this.repeater.Latitude
            ], this.range(this.repeater.Radius, this.repeater.HAAT), 32);

            let geoJsonCoverage = {
                type: "FeatureCollection",
                features: []
            };

            geoJsonCoverage.features.push({
                id: this.repeater.ID,
                type: "Feature",
                properties: {
                    "id": this.repeater.ID,
                    "fill": this.repeater.Status === 'Online' ? this.$vuetify.theme.themes.dark.success : this.$vuetify.theme.themes.dark.error,
                    "fill-opacity": 0.5,
                    "stroke": this.repeater.Status === 'Online' ? this.$vuetify.theme.themes.dark.success : this.$vuetify.theme.themes.dark.error,
                    "stroke-width": 0.5,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [ret]
                }
            });

            this.geoJsonSourceCoverage.data = geoJsonCoverage;

            if (!this.showClickBanner) this.showMap();
        }
    }
</script>
